import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { userService, roleService } from '../../_services'
import UserForm from './userform.component'
import { useTranslation } from "react-i18next";

function User() {

  const [roles, setRoles] = useState([])
  const {t} = useTranslation('common');

  const initialState = {
      customer: -1,
      username: '',
      password: '',
      email: '',
      enabled: true,
      accountExpired: false,
      accountLocked: false,
      passwordExpired: false,
      twoFactorAuthentication: false,
      sendTicketAlerts: false,
      userRoles: []
  }

  useEffect(() => {
    roleService.getAll().then(roles => {
        setRoles(roles)
    })
  }, [])

  const formatBoolean = function(cell) {
    return cell ? t('crudtable.yes') : t('crudtable.no')
  }

  const formatRoles = function(cell, row) {
    var result = []
    row.userRoles.forEach(userRole => {
        var role = roles.find(role => role.id == userRole.role)
        if (role) {
            if (role.authority == 'ROLE_ADMIN') {
                result.push(t('user.roles.admin'))
            } else if (role.authority == 'ROLE_CUSTOMER') {
                result.push(t('user.roles.customer'))
            } else {
                result.push(role.authority)
            }
        }
    })
    return result.join(', ')
  }

  const columns = [
    {
      dataField: 'username',
      text: t('user.columns.username'),
      sort: true
    },
    {
      dataField: 'customerName',
      text: t('user.columns.customer'),
      sort: true
    },
    {
      dataField: 'enabled',
      text: t('user.columns.enabled'),
      sort: true,
      formatter: formatBoolean
    },
    {
      dataField: 'accountExpired',
      text: t('user.columns.accountexpired'),
      sort: true,
      formatter: formatBoolean
    },
    {
      dataField: 'accountLocked',
      text: t('user.columns.accountlocked'),
      sort: true,
      formatter: formatBoolean
    },
    {
      dataField: 'passwordExpired',
      text: t('user.columns.passwordexpired'),
      sort: true,
      formatter: formatBoolean
    },
    {
      dataField: 'twoFactorAuthentication',
      text: t('user.columns.twofactorauthentication'),
      sort: true,
      formatter: formatBoolean
    },
    {
      dataField: 'roles',
      text: t('user.columns.role'),
      sort: true,
      formatter: formatRoles
    }
  ]

  return (
    <div>
      <h2>
        {t('user.label')}
      </h2>
      { roles.length > 0 &&
	      <CrudTable
	        service={userService}
	        columns={columns}
	        initialState={initialState}
	        form={<UserForm />}
	        remote={{"filter": false, "search": false, "pagination": false}}
	        sort={{dataField: 'username', order: 'asc'}}
	      />
      }
    </div>
  )
}

export default User;